import { call, all, takeLeading, fork, put, select, takeEvery } from 'redux-saga/effects';
import AuthService from 'services/authService';
import { api, apiCall } from 'redux/helpers/api';
import { userActions } from 'redux/user';
import NotificationService from 'services/notificationService';
import { snackbarActions } from 'redux/snackbar';
import history from 'providers/history';
import { refreshToken } from 'redux/helpers/token';
import { isFailedToFetchError } from 'redux/helpers/utils';
import types from './types';
import actions from './actions';
import selectors from './selectors';

// Initial load's (Refresh) and login's response is the same and actions are same.
function* handleLoginResponse({ ok, response, error }) {
  if (ok) {
    const { me, token, workspaces, intercom } = response;
    yield call(AuthService.login, token);
    yield put(actions.loginSuccess({ user: me, workspace: workspaces[0], intercom }));
    yield put(userActions.getNotifications());
    yield put(userActions.subscribeToNotifications());
    yield call(NotificationService.firebase.askPermission);

    const count = yield select(selectors.getNotificationCount);
    yield call(NotificationService.firebase.setBadgeNumber, count);
  } else if (!isFailedToFetchError(error)) {
    yield call(AuthService.logout);
    yield put(actions.loginFailure(error));
  }
}

function* onRefreshToken() {
  yield call(refreshToken);
}

function* onGetZendeskToken() {
  const { ok, response, error } = yield apiCall(api.auth.getZendeskToken);

  if (ok) {
    yield put(actions.getZendeskTokenSuccess(response));
  } else {
    yield put(actions.getZendeskTokenFailure(error));
  }
}

// On app launch do the refreshToken call to server,
// and trigger either loginSuccess or loginFailure
function* onAppLaunch() {
  const token = yield call(AuthService.getToken);

  if (token) {
    const data = yield call(refreshToken);

    yield handleLoginResponse(data);
  } else {
    yield put(actions.loginFailure());
  }
}

function* onLogin({ payload }) {

  const fcmToken = yield call(AuthService.getFcmToken);
  const apnsToken = yield call(AuthService.getAPNSToken);

  const data = yield apiCall(api.auth.login, {
    ...(fcmToken && { fcmToken }),
    ...(apnsToken && { apnsToken }),
    ...payload,
  });

  yield handleLoginResponse(data);

  const { ok, error } = data;

  if (ok) {
    yield put(snackbarActions.createSuccess('login.success'));
  } else if (isFailedToFetchError(error)) {
    yield put(snackbarActions.createFailure('login.failedToFetch', { translate: true }));
  } else {
    yield put(snackbarActions.createFailure('login.failure', { translate: true }));
  }
}

function* onLogout() {
  const fcmToken = yield call(AuthService.getFcmToken);
  const apnsToken = yield call(AuthService.getAPNSToken);

  const { error, ok } = yield apiCall(api.auth.logout, {
    ...(fcmToken && { fcmToken }),
    ...(apnsToken && { apnsToken }),
  });

  // Regardless of what happens (maybe server unreachable), clear the local token
  yield call(AuthService.logout);

  if (ok || error.message === 'Unauthenticated.') {
    yield put(actions.logoutSuccess());
  } else {
    yield put(actions.logoutFailure(error.message));
  }
}

function* onSendForgotEmail({ payload, config = {} }) {
  const { ok, error } = yield apiCall(api.auth.forgot, payload, config);
  if (ok) {
    yield put(snackbarActions.createSuccess('auth.sendForgotPasswordInstructions'));
    yield call(history.push, '/');
  } else {
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onPasswordReset({ payload, config = {} }) {
  const { ok, error } = yield apiCall(api.auth.reset, payload, config);
  if (ok) {
    yield put(snackbarActions.createSuccess('auth.passwordReset'));
    yield call(history.push, '/login');
  } else {
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onEmailVerification({ payload, config = {} }) {
  const { ok, error } = yield apiCall(api.auth.verifyEmail, payload, config);
  if (ok) {
    yield put(snackbarActions.createSuccess('auth.emailVerification'));
  } else {
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onEmailVerificationValidation({ payload, config = {} }) {
  const { error } = yield apiCall(api.auth.verifyEmailValidate, payload, config);
  if (error) {
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onVerifyToken({ payload, config = {} }) {
  const { error } = yield apiCall(api.auth.verifyToken, payload, config);
  if (error) {
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onRegister({ payload, config = {} }) {

  const fcmToken = yield call(AuthService.getFcmToken);
  const apnsToken = yield call(AuthService.getAPNSToken);

  const data = yield apiCall(api.auth.register, {
    ...(fcmToken && { fcmToken }),
    ...(apnsToken && { apnsToken }),
    ...payload,
  }, config);

  const { ok, error } = data;

  if (ok) {
    yield handleLoginResponse(data);
    yield call(history.push, '/welcome');
    yield put(snackbarActions.createSuccess('auth.register'));
  } else {
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onJoin({ payload, config = {} }) {

  const fcmToken = yield call(AuthService.getFcmToken);
  const apnsToken = yield call(AuthService.getAPNSToken);

  const data = yield apiCall(api.auth.join, {
    ...(fcmToken && { fcmToken }),
    ...(apnsToken && { apnsToken }),
    ...payload,
  }, config);

  const { ok, error } = data;

  if (ok) {
    yield handleLoginResponse(data);
    yield call(history.push, '/welcome');
    yield put(snackbarActions.createSuccess('auth.register'));
  } else {
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onGetProfile() {
  const { ok, error, response } = yield apiCall(api.user.getProfile);
  if (ok) {
    yield put(actions.getProfileSuccess(response));
  } else {
    yield put(actions.getProfileFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onDeleteAccount({ payload }) {
  const fcmToken = yield call(AuthService.getFcmToken);
  const apnsToken = yield call(AuthService.getAPNSToken);

  const { error, ok } = yield apiCall(api.auth.deleteAccount, {
    ...(fcmToken && { fcmToken }),
    ...(apnsToken && { apnsToken }),
    ...payload,
  });

  if (ok) {
    yield put(actions.deleteAccountSuccess());
    yield call(AuthService.logout);
    yield put(actions.logoutSuccess());
    yield put(snackbarActions.createSuccess('Account deleted!'));
  } else {
    yield put(actions.deleteAccountFailure());
    yield put(snackbarActions.createFailure(error.message));
  }
}

export default function* authSagas() {
  yield all([
    takeLeading(types.login, onLogin),
    takeLeading(types.logout, onLogout),
    takeLeading(types.refreshToken, onRefreshToken),

    takeLeading(types.sendForgotEmail, onSendForgotEmail),
    takeLeading(types.resetPassword, onPasswordReset),

    takeLeading(types.verifyToken, onVerifyToken),
    takeLeading(types.verifyEmail, onEmailVerification),
    takeLeading(types.validateEmailVerificationCode, onEmailVerificationValidation),
    takeLeading(types.register, onRegister),
    takeLeading(types.join, onJoin),

    takeLeading(types.getProfile, onGetProfile),

    takeLeading(types.getZendeskToken, onGetZendeskToken),

    takeEvery(types.deleteAccount, onDeleteAccount),

    fork(onAppLaunch),
  ]);
}
