import { authSelectors } from 'redux/auth';
import { useSelector } from 'react-redux';
import config from 'config';

const fallbackFeature = { enabled: false };

const useFeature = name => {
  const getFeatureSelector = useSelector(authSelectors.getFeatureSelector);

  const feature = getFeatureSelector(name) || fallbackFeature;

  if (name === 'chat') {
    feature.enabled = feature.enabled && !!config.talkjs.appId;
  }

  return feature;
};

export default useFeature;
