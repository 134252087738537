import config from 'config';

const init = () => {
  if (config.intercom.enabled) {
    window.intercomSettings = {
      alignment: 'right',
      // vertical_padding: 20, // Adjust vertical padding from the bottom
      // horizontal_padding: 20, // Adjust horizontal padding from the right
    };

    // eslint-disable-next-line
    (() => { const w = window; const ic = w.Intercom; if (typeof ic === 'function') { ic('reattach_activator'); ic('update', w.intercomSettings); } else { const d = document; const i = function () { i.c(arguments); }; i.q = []; i.c = function (args) { i.q.push(args); }; w.Intercom = i; const l = function () { const s = d.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = `https://widget.intercom.io/widget/${config.intercom.appId}`; const x = d.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x); }; if (w.attachEvent) { w.attachEvent('onload', l); } else { w.addEventListener('load', l, false); } } })();
  }
};

export default init;
