import { workspaceTypes } from 'redux/workspace';
import update from 'immutability-helper';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';

const handleMassActionOnMember = (state, members, updateObj) => update(state, members.reduce((acc, memberId) => ({
  ...acc,
  [memberId]: updateObj,
}), {}));

const handleMassAction = (state, { action, members, data }) => {
  switch (action) {

    case 'add_to_group':
      return handleMassActionOnMember(state, members, { groups: { $apply: (existing) => uniq([...existing, ...data.groupIds]) } });

    case 'remove_from_group':
      return handleMassActionOnMember(state, members, { groups: { $apply: (existing) => existing.filter((id) => !data.groupIds?.includes(id)) } });

    case 'add_label':
      return handleMassActionOnMember(state, members, { labels: { $apply: (existing) => uniqBy([...existing, ...data.labels], 'id') } });

    case 'add_analytical_label':
      return handleMassActionOnMember(state, members, { analyticalLabels: { $apply: (existing) => uniqBy([...existing, ...data.labels], 'id') } });

    case 'remove_label':
      return handleMassActionOnMember(state, members, { labels: { $apply: (existing) => existing.filter(({ id }) => !data.labels?.includes(id)) } });

    case 'remove_analytical_label':
      return handleMassActionOnMember(state, members, { analyticalLabels: { $apply: (existing) => existing.filter(({ id }) => !data.labels?.includes(id)) } });

    case 'change_role':
      return handleMassActionOnMember(state, members, { role: { $set: data.role } });

    case 'delete':
      return update(state, { $unset: members });

    default:
      return state;
  }
};

const membersReducer = (state = {}, { type, payload }) => {
  switch (type) {

    case workspaceTypes.memberMassActionSuccess:
      return handleMassAction(state, payload);

    case workspaceTypes.deleteMemberSuccess:
      return update(state, { $unset: [payload.memberId] });

    default:
      return state;
  }
};

export default membersReducer;
