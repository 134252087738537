import memoize from 'lodash/memoize';
import { createSelector } from 'reselect';

const getToken = ({ chat: { auth } }) => auth?.token;
const getUserId = ({ chat: { auth } }) => auth?.userId;

const getConversationEntities = ({ entities: { conversations } }) => conversations;

const getConversationSelector = createSelector(
  [getConversationEntities],
  (conversations) => memoize(conversationId => conversations?.[conversationId]),
);

const getConversableMemberIds = ({ chat: { conversableMemberIds } }) => conversableMemberIds;

const getConversationMembers = ({ chat: { conversationMembers } }) => conversationMembers;

const getMemberIdsForConversation = createSelector(
  [getConversationMembers],
  (conversationMembers) => memoize(conversationId => conversationMembers?.[conversationId] ?? []),
);

export default {
  getToken,
  getUserId,


  getConversableMemberIds,

  getConversationSelector,

  getMemberIdsForConversation,
};
