import IconButton from '@mui/material/IconButton';
import React, { useState } from 'react';
import Badge from '@mui/material/Badge';
import { useUnreads } from '@talkjs/react';
import MessagingIcon from 'components/Icons/Messaging';
import { isMobile } from 'helpers';
import ChatBottomDrawer from 'containers/Chat/ChatBottomDrawer';
import useSideQueryParam from '../useSideQueryParam';

const ChatIconButton = (args) => {

  const [, setSide] = useSideQueryParam();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const unreads = useUnreads();

  const onClick = () => {
    if (isMobile) {
      setDrawerOpen(true);
    } else {
      setSide('inbox');
    }
  };

  return (
    <>
      <IconButton onClick={onClick} {...args}>
        <Badge badgeContent={unreads?.length} color="primary">
          <MessagingIcon />
        </Badge>
      </IconButton>

      {isMobile && (
        <ChatBottomDrawer open={drawerOpen} onOpen={() => setDrawerOpen(true)} onClose={() => setDrawerOpen(false)} />
      )}
    </>
  );
};

export default ChatIconButton;
