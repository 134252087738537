import React from 'react';
import Grid from 'components/Grid';
import { isEmail } from 'validators';
import PropTypes from 'propTypes';
import TelephoneQuickAction from './TelephoneQuickAction';
import EmailQuickAction from './EmailQuickAction';
import MemberProfileQuickAction from './MemberProfileQuickAction';
import ChatQuickAction from './ChatQuickAction';

const MemberQuickActions = ({ contactNumber, contactEmail, profileMemberId, chatMemberId }) => {

  const edgeOnce = ['start'];

  return (
    <Grid container direction="row" spacing={1}>
      {contactNumber && (
        <Grid item>
          <TelephoneQuickAction value={contactNumber} edge={edgeOnce.shift()} />
        </Grid>
      )}

      {contactEmail && isEmail(contactEmail) && (
        <Grid item>
          <EmailQuickAction value={contactEmail} edge={edgeOnce.shift()} />
        </Grid>
      )}

      {profileMemberId && (
        <Grid item>
          <MemberProfileQuickAction memberId={profileMemberId} edge={edgeOnce.shift()} />
        </Grid>
      )}

      {chatMemberId && (
        <Grid item>
          <ChatQuickAction memberId={chatMemberId} edge={edgeOnce.shift()} />
        </Grid>
      )}
    </Grid>
  );
};

MemberQuickActions.propTypes = {
  contactNumber: PropTypes.string,
  contactEmail: PropTypes.string,
  profileMemberId: PropTypes.any,
  chatMemberId: PropTypes.any,
};

export default MemberQuickActions;
