import AuthService from 'services/authService';
import store from 'redux/store';
import { authActions } from 'redux/auth';
import { camelizeKeys } from 'humps';
import { snackbarActions } from 'redux/snackbar';
import { getCommonHeaders, getFullUrl, isFailedToFetchError } from './utils';

let tokenRefreshPromise = null;

const fetchNewTokenUrl = getFullUrl('/auth/refresh');
const fetchNewToken = ({ token, fcmToken, apnsToken }) => fetch(fetchNewTokenUrl, {
  method: 'POST',
  priority: 'high',
  mode: 'cors', // TODO: Needed?
  headers: {
    'Content-Type': 'application/json',
    ...getCommonHeaders(token),
  },
  ...((fcmToken || apnsToken) && {
    body: JSON.stringify({
      ...(fcmToken && { fcm_token: fcmToken }),
      ...(apnsToken && { apns_token: apnsToken }),
    }),
  }),
});

const refreshTokenFail = async (error) => {
  if (!isFailedToFetchError(error)) {
    await AuthService.logout();

    store.dispatch(authActions.refreshTokenFailure(error));
  }

  store.dispatch(snackbarActions.createFailure(error.message, { translate: false }));

  return { ok: false, error, data: null, status: 401 };
};

export const refreshToken = async () => {
  const token = await AuthService.getToken();
  if (!token) {
    return refreshTokenFail({ message: 'Member is not logged in' });
  }

  const fcmToken = await AuthService.getFcmToken();
  const apnsToken = await AuthService.getAPNSToken();

  return fetchNewToken({ token, fcmToken, apnsToken })
    .catch(() => {
      // Standardise all failed to reach server errors
      throw new TypeError('Failed to fetch');
    })
    .then(async (response) => {
      if (!response.ok) {
        return refreshTokenFail({ message: 'Token refresh failed' });
      }

      const { data } = camelizeKeys(await response.json());

      await AuthService.setToken(data.token);

      store.dispatch(authActions.refreshTokenSuccess({
        user: data?.me,
        workspace: data?.workspaces?.[0],
        intercom: data?.intercom,
      }));

      return { ok: true, response: data, error: null };
    })
    .catch(refreshTokenFail);
};

export const ensureValidToken = async () => {
  const token = await AuthService.getToken();
  if (!token) {
    return refreshTokenFail({ message: 'Member is not logged in' });
  }

  const shouldRefreshToken = await AuthService.shouldRefreshToken();
  if (!shouldRefreshToken) {
    return; // Return last successful promise
  }

  tokenRefreshPromise = tokenRefreshPromise || refreshToken()
    .finally(() => {
      tokenRefreshPromise = null;
    });

  return tokenRefreshPromise;
};
