import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import Avatar from 'components/Avatar';
import NavLink from 'components/NavLink';
import MemberPopover from 'containers/MemberPopover';
import PostContext from './PostContext';
import PostHeaderImportant from './PostHeaderImportant';

const Wrapper = styled('div')`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  flex: 1 1 auto;
  margin: 0.5rem 1rem;

  overflow: hidden;
`;

const Meta = styled('div')`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1 1 auto;
  padding: 0.25rem 0 0.25rem 0.75rem;
`;

const Name = styled('div')`
  font-size: 1rem;
  font-weight: 700;
  text-decoration: none;
  margin-right: auto;
  color: inherit;
`;

const Primary = styled('div')`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Secondary = styled('div')`
  display: flex;
  align-items: center;

  font-size: 0.875rem;
  margin-top: 0.1rem;
  white-space: nowrap;
  color: #666;

  ${({ isImportant, isCompact }) => isImportant && isCompact && 'color: #333;'}

  @media (max-width: 360px) { font-size: 12px; }
`;

const PostHeader = ({ firstName, lastName, avatar, memberId, info, ...rest }) => {

  const { isCompact, isImportant } = useContext(PostContext);

  const name = [firstName, lastName].filter(v => !!v).join(' ');

  const to = `/member/${memberId}`;
  const avatarLink = memberId && { component: NavLink, to };
  const memberLink = memberId && { as: NavLink, to };

  return (
    <Wrapper {...rest}>
      <Avatar size={40} firstName={firstName} lastName={lastName} src={avatar?.src} srcSet={avatar?.srcSet} tabIndex={-1} {...avatarLink} />
      <Meta>
        <Primary>
          <MemberPopover memberId={memberId}>
            <Name translate="no" {...memberLink}>{`${name}`}</Name> {isImportant && <PostHeaderImportant />}
          </MemberPopover>
        </Primary>
        {info && <Secondary isImportant={isImportant} isCompact={isCompact}>{info}</Secondary>}
      </Meta>
    </Wrapper>
  );
};

PostHeader.propTypes = {
  memberId: PropTypes.any,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  avatar: PropTypes.shape({
    src: PropTypes.string,
    srcSet: PropTypes.string,
  }),

  actions: PropTypes.node,

  info: PropTypes.node,
};

export default PostHeader;
