import React from 'react';
import { createTheme } from '@mui/material/styles';
import { isMobile } from 'helpers';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { transparentize } from 'polished';
import { etEE, enUS, ruRU, fiFI, svSE, ukUA, plPL, hiIN, deDE } from '@mui/material/locale';
import merge from 'lodash/merge';

const defaultColor = '#46a248';
const defaultDark = false;

const ButtonConf = {
  defaultProps: {
    variant: 'contained',
    color: 'primary',
    disableElevation: true,
  },
  styleOverrides: {
    root: {
      textTransform: 'none',
      fontWeight: 600,
    },
  },
};

const generateTheme = ({ color, isDark, locale }) => {
  const theme = createTheme({
    palette: {
      mode: isDark ? 'dark' : 'light',
      primary: {
        main: color,
      },
      secondary: {
        main: color,
      },
      default: {
        main: isDark ? '#333' : '#eee',
        contrastText: isDark ? '#eee' : '#333',
      },
      text: {
        primary: isDark ? '#eee' : '#333',
        secondary: '#666',
      },
    },
    typography: {
      fontFamily: 'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
      fontSize: isMobile ? 12 : 14,
      htmlFontSize: isMobile ? 14 : 15,
      body1: { fontSize: '1rem' },
      button: { fontSize: '0.9rem' },
    },
    components: {
      MuiAvatar: {
        styleOverrides: {
          colorDefault: {
            color: '#fff', // Should be contrastText
            backgroundColor: color,
          },
        },
      },
      MuiDataGrid: {
        styleOverrides: {
          root: {
            border: 'none',
          },
          columnSeparator: {
            color: '#eee',
          },
          withBorderColor: {
            borderColor: '#eee',
            '&:focus, &:focus-visible, &:focus-within': {
              outline: 'none',
            },
            '&:focus-visible': {
              boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
            },
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            padding: '40px 40px 20px 40px', // Actually wanted visual paddings
            ...(isMobile && { padding: '20px 20px 10px 20px' }),
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          divider: {
            borderBottomColor: '#f5f5f5',
          },
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            minWidth: 32,
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            padding: '20px 40px', // Actually wanted visual paddings
            margin: '0 -40px', // Escape MuiDialog from the edges

            ...(isMobile && { padding: '10px 20px' }),
            ...(isMobile && { margin: '0 -20px' }),
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          outlined: {
            borderColor: '#eee',
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            padding: '20px 40px', // Actually wanted visual paddings
            margin: '0 -40px -20px -40px', // Escape MuiDialog from the edges

            ...(isMobile && { padding: '10px 20px' }),
            ...(isMobile && { margin: '0 -20px -10px -20px' }),
          },
        },
      },
      MuiDialogTitle: {
        defaultProps: {
          component: 'div',
          as: 'div',
        },
        styleOverrides: {
          root: {
            fontWeight: 600,
            fontSize: '1.125rem',
            padding: '40px 40px 20px 40px', // Actually wanted paddings
            margin: '-40px -40px 0 -40px', // Escape MuiDialog from the edges

            ...(isMobile && { padding: '20px 20px 10px 20px' }),
            ...(isMobile && { margin: '-20px -20px 0 -20px' }),
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          underline: {
            '&&:before': {
              borderColor: '#ddd',
            },
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontSize: 13,
          },
        },
      },
      MuiTableSortLabel: {
        styleOverrides: {
          icon: {
            '&&&&&': {
              color: 'inherit',
            },
          },
        },
      },
      MuiTabScrollButton: {
        styleOverrides: {
          root: {
            '&$disabled': {
              opacity: 0.2,
            },
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            'table:not(.no-bg) &:nth-of-type(even)': {
              backgroundColor: '#F9F9F9',
            },
            '&:last-child td': {
              borderBottom: 'none',
            },
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: '1px solid #eee',
          },
          head: {
            fontWeight: 600,
          },
          stickyHeader: {
            backgroundColor: '#fff',
            '&.MuiTableCell-paddingCheckbox:first-of-type': {
              left: 'initial',
            },
          },
        },
      },
      MuiButtonGroup: {
        defaultProps: {
          disableElevation: true,
        },
      },
      MuiButton: ButtonConf,
      MuiLoadingButton: ButtonConf,
      MuiInputLabel: {
        defaultProps: {
          shrink: true,
        },
        styleOverrides: {
          root: {
            '&.MuiInputLabel-shrink': {
              maxWidth: 'calc(100% / 0.85 )',
            },
            '.disable-input-absolute-shrink &.MuiInputLabel-shrink': {
              position: 'relative',
              transform: 'none',
              fontSize: 12,
              maxWidth: '100%',
              whiteSpace: 'wrap',
              marginBottom: '-1rem',
            },
            '.disable-input-absolute-shrink.disable-input-absolute-shrink--big-font &.MuiInputLabel-shrink': {
              fontSize: '1rem',
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor: '#ddd',
          },
        },
      },
      MuiFormControl: {
        defaultProps: {
          fullWidth: true,
          margin: 'normal',
          variant: 'standard',
        },
      },
      MuiUseMediaQuery: {
        noSsr: true,
      },
      MuiTextField: {
        defaultProps: {
          fullWidth: true,
          variant: 'standard',
          InputLabelProps: {
            shrink: true,
          },
        },
      },
      MuiRadio: {
        defaultProps: {
          color: 'primary',
          disableRipple: !!isMobile, // Ripple has problems on some devices
          checkedIcon: <CheckCircleIcon />,
        },
      },
      MuiCheckbox: {
        defaultProps: {
          color: 'primary',
          disableRipple: !!isMobile, // Ripple has problems on some devices
        },
      },
    },
    //
    // CUSTOM
    //
    transparentColor: amount => transparentize(amount, color),
    maxWidth: isMobile ? '650px' : '1400px',

    // Main content / feed width
    maxContentWidth: {
      default: '650px',
      medium: '800px',
      wide: '1100px',
    },

    // Where sidebar appears / disappears
    sidebarBreakpoint: '600px',

    feedImageSrcSetSizes: isMobile
      ? '(max-width: 650px) 100vw, 650px' // 100vw until maxContentWidth, then capped at maxContentWidth
      : '(max-width: 600px) 100vw, 650px', // 100vw until sidebarBreakpoint, then capped at maxContentWidth
  });

  theme.zIndex.fullScreenHeader = theme.zIndex.appBar - 7;
  theme.zIndex.navbar = theme.zIndex.appBar - 6;

  // Default tabbar drawers (contact directory, chat etc...)
  theme.zIndex.tabbarBackdrop = theme.zIndex.appBar - 5;
  theme.zIndex.tabbarDrawer = theme.zIndex.appBar - 4;

  // Hub drawer always on top of other tabbar drawers
  theme.zIndex.hubBackdrop = theme.zIndex.appBar - 3;
  theme.zIndex.hubDrawer = theme.zIndex.appBar - 2;

  // Tabbar always on top of the drawers
  theme.zIndex.tabbar = theme.zIndex.appBar - 1;

  // Currently not easy to hide tabbar - put chat drawer on top
  theme.zIndex.chatBackdrop = theme.zIndex.tabbar + 1;
  theme.zIndex.chatDrawer = theme.zIndex.tabbar + 2;

  theme.zIndex.overflowMenu = theme.zIndex.modal + 1;

  theme.zIndex.chatPopups = theme.zIndex.drawer - 2;
  theme.zIndex.helpFloat = theme.zIndex.drawer - 1;

  return merge(locale, theme);
};

const localeMap = {
  et: etEE,
  en: enUS,
  ru: ruRU,
  fi: fiFI,
  // 'lv': ,
  // 'lt': ,
  sv: svSE,
  uk: ukUA,
  pl: plPL,
  hi: hiIN,
  de: deDE,
};

export default ({ color, isDark, language }) => {
  const themeColor = color || defaultColor;
  const themeDark = isDark || defaultDark;

  const locale = localeMap[language] || enUS;

  try {
    return generateTheme({ color: themeColor, isDark: themeDark, locale });
  } catch (e) {
    console.error(e);
    // Something wrong with the color. Use default instead
    return generateTheme(defaultColor, defaultDark, locale);
  }
};
