import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import DrawerBody from 'containers/Side/Drawer/DrawerBody';
import { Inbox } from '@talkjs/react';
import useChat from 'hooks/useChat';
import Grid from 'components/Grid';
import Button from 'components/Button';
import CreateRoundedIcon from '@mui/icons-material/CreateRounded';
import DrawerContent from 'containers/Side/Drawer/DrawerContent';
import { useTranslation } from 'react-i18next';
import CreateConversationForm from 'containers/Chat/CreateConversationForm';
import useSideQueryParam from '../useSideQueryParam';
import SideContext from '../SideContext';


const SideChatInbox = () => {

  const [, setSide] = useSideQueryParam();
  const { setTitle } = useContext(SideContext);
  const { t } = useTranslation('chat');

  const scrollBody = useRef();

  const [mode, setMode] = useState();

  const { onShowConversation } = useChat();

  const onSelectConversation = useCallback((event) => {
    if (!event.conversation) {
      return;
    }
    setSide();
    onShowConversation(event.conversation.id);

    event.preventDefault();
  }, [onShowConversation, setSide]);

  useEffect(() => {
    if (mode === 'create') {
      setTitle({
        children: (
          <Grid container direction="row" wrap="nowrap" spacing={1} alignItems="center" justifyContent="space-between">
            <Grid item>{t('chat:newChat.title')}</Grid>
          </Grid>
        ),
        backButton: { onClick: () => setMode('view') },
      });
    } else {
      setTitle({
        children: (
          <Grid container direction="row" wrap="nowrap" spacing={1} alignItems="center" justifyContent="space-between">
            <Grid item>{t('chat:side.title')}</Grid>
            <Grid item>
              <Button variant="text" size="small" startIcon={<CreateRoundedIcon />} edge="end" onClick={() => setMode('create')}>{t('chat:side.createNewChat')}</Button>
            </Grid>
          </Grid>
        ),
        backButton: { onClick: () => setSide() },
      });
    }
  }, [mode, setSide, setTitle, t]);

  const onCreateSuccess = ({ data: conversationId }) => {
    onShowConversation(conversationId);
    setSide();
  };

  if (mode === 'create') {
    return (
      <DrawerBody>
        <DrawerContent>
          <CreateConversationForm
            onSuccess={onCreateSuccess}
          />
        </DrawerContent>
      </DrawerBody>
    );
  }

  return (
    <DrawerBody ref={scrollBody}>
      <Inbox
        showChatHeader={false}
        showFeedHeader={false}
        showMobileBackButton={false}

        selected={null}

        theme="guavahr"

        onSelectConversation={onSelectConversation}

        style={{ height: '100%', width: '100%' }}
      />
    </DrawerBody>
  );
};

export default SideChatInbox;
