import React, { useEffect, useMemo } from 'react';
import Grid from 'components/Grid';
import Button from 'components/Button';
import Form from 'components/FinalForm/Form';
import { useDispatch, useSelector } from 'react-redux';
import { chatActions, chatSelectors, chatTypes } from 'redux/chat';
import MemberAutocomplete from 'containers/Fields/MemberAutocomplete';
import { useTranslation } from 'react-i18next';
import useReduxLoading from 'hooks/useReduxLoading';
import { authSelectors } from 'redux/auth';
import PropTypes from 'prop-types';


const CreateConversationForm = ({ onCancelClick, ...rest }) => {

  const dispatch = useDispatch();
  const { t } = useTranslation('chat');

  useEffect(() => {
    dispatch(chatActions.getConversableMembers());
  }, [dispatch]);

  const options = useSelector(chatSelectors.getConversableMemberIds);
  const optionsLoading = useReduxLoading(
    chatTypes.getConversableMembers,
    [chatTypes.getConversableMembersSuccess, chatTypes.getConversableMembersFailure],
    true,
  );


  const authMemberId = useSelector(authSelectors.getMemberId);
  const initialValues = useMemo(() => ({
    memberIds: [],
  }), []);

  const beforeSubmit = ({ memberIds, ...values }) => ({
    ...values,
    memberIds: [authMemberId, ...memberIds],
  });

  return (
    <Form
      start={chatTypes.createConversation}
      resolve={chatTypes.createConversationSuccess}
      reject={chatTypes.createConversationFailure}
      beforeSubmit={beforeSubmit}

      initialValues={initialValues}

      {...rest}
    >
      {({ handleSubmit }) => (
        <Grid container spacing={2} direction="column" wrap="nowrap">
          <Grid item>
            <MemberAutocomplete.FinalForm
              multiple
              name="memberIds"
              label={t('chat:newChat.membersLabel')}
              placeholder={t('chat:newChat.membersPlaceholder')}

              options={options}
              loading={optionsLoading}

              disableCloseOnSelect
              getOptionDisabled={memberId => +memberId === +authMemberId}
            />
          </Grid>
          <Grid item>
            <Grid container direction="row" wrap="nowrap" spacing={2} alignItems="center" justifyContent="flex-end">
              {onCancelClick && <Grid item><Button variant="text" onCancelClick={onCancelClick}>{t('chat:newChat.cancel')}</Button></Grid>}
              <Grid item><Button onClick={handleSubmit}>{t('chat:newChat.create')}</Button></Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Form>
  );
};

CreateConversationForm.propTypes = {
  onCancelClick: PropTypes.func,
};

export default CreateConversationForm;
