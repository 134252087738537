import React, { useContext } from 'react';
import Drawer from 'containers/Side/Drawer/Drawer';
import DrawerTitle from 'containers/Side/Drawer/DrawerTitle';
import { useHistory, useLocation } from 'react-router';
import { authSelectors } from 'redux/auth';
import { useSelector } from 'react-redux';
import useFeature from 'hooks/useFeature';
import MemberEvents from './MemberEvents/MemberEvents';
import { getCloseButtonLink } from './MemberEvents/memberEventsService';
import ContactDirectory from './ContactDirectory/ContactDirectory';
import SideProvider from './SideProvider';
import ViewContext from './ViewContext';
import ManageContacts from './ContactDirectory/ManageContacts';
import SideChatInbox from './Chat/SideChatInbox';

const Side = () => {

  const history = useHistory();
  const location = useLocation();

  const PossibleContent = {};
  const extraProps = {};

  const { side } = useContext(ViewContext);

  const birthdayFeature = useFeature('birthdays');
  const jobAnniversaryFeature = useFeature('jobAnniversaries');
  const peopleDirectoryFeature = useFeature('peopleDirectory');
  const chatFeature = useFeature('chat');

  if (birthdayFeature.enabled) {
    PossibleContent.birthdays = MemberEvents;
  }

  if (chatFeature.enabled) {
    PossibleContent.inbox = SideChatInbox;
    extraProps.inbox = {
      PaperProps: { sx: { width: 400 } },
    };
  }

  if (jobAnniversaryFeature.enabled) {
    PossibleContent.jobAnniversaries = MemberEvents;
  }

  if (peopleDirectoryFeature.enabled) {
    PossibleContent.directory = ContactDirectory;
    PossibleContent.contacts = ManageContacts;
    extraProps.directory = {};
    extraProps.contacts = {};
  }

  const Content = PossibleContent[side];
  const contentSpecificProps = extraProps[side] ?? { PaperProps: { sx: { width: 400 } } };

  // All functionality in Side requres workspaceId
  // When not present then defer rendering anything at all.
  const workspaceId = useSelector(authSelectors.getWorkspaceId);

  if (!workspaceId || !Content) {
    return null;
  }

  return (
    <SideProvider>
      <Drawer
        open={!!side}
        anchor="right"
        onClose={() => history.replace(getCloseButtonLink(location))}
        {...contentSpecificProps}
      >
        <DrawerTitle />
        <Content />
      </Drawer>
    </SideProvider>
  );
};

const SideWithProvider = () => (
  <SideProvider>
    <Side />
  </SideProvider>
);

export default SideWithProvider;
