import { combineReducers } from 'redux';
import types from './types';

const auth = (state = {}, { type, payload }) => {
  switch (type) {
    case types.getTokenSuccess:
      return payload.data;
    default:
      return state;
  }
};

const conversationMembers = (state = {}, { type, payload }) => {
  switch (type) {
    case types.getMembersForConversationSuccess:
      return {
        ...state,
        [payload.request.conversationId]: payload.data,
      };
    default:
      return state;
  }
};

const conversableMemberIds = (state = [], { type, payload }) => {
  switch (type) {
    case types.getConversableMembersSuccess:
      return payload.data;
    default:
      return state;
  }
};

const chatReducers = combineReducers({
  auth,
  conversationMembers,
  conversableMemberIds,
});

export default { chat: chatReducers };
