import React, { useRef, useState, useEffect } from 'react';

import NavLink from 'components/NavLink';
import Tabbar, { Tab } from 'components/Tabbar';
import { useTranslation } from 'react-i18next';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Backdrop from '@mui/material/Backdrop';
import styled from '@emotion/styled/macro';
import history from 'providers/history';
import PlusRoundedIcon from 'components/Icons/PlusRounded';
import Search from 'containers/Search/Search';
import Grid from 'components/Grid';
import AppsRoundedIcon from '@mui/icons-material/AppsRounded';
import CommentIcon from 'components/Icons/Comment';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import TrophyIcon from 'components/Icons/Trophy';
import useSmartPostCreateInitialValues from 'hooks/useSmartPostCreateInitialValues';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import useNavigationItems from 'containers/Sidebar/useNavigationItems';
import { useTheme } from '@emotion/react';
import Hub from './Hub';
import HubButton from './HubButton';

const NavbarBackdrop = styled(Backdrop)`
  z-index: ${({ theme }) => theme.zIndex.navbar};
`;

const SpecialTab = styled(Tab)`
  position: relative;
  color: #fff;

  &::before {
    content: '';
    position: absolute;
    width: 40px;
    height: 40px;
    background: ${({ theme }) => theme.palette.primary.main};
    border-radius: 50%;
    z-index: -1;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`;

const GridWithActualGrid = styled(Grid)`
  display: grid;

  grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));

  @media(min-width: 321px) {
    grid-template-columns: repeat(auto-fit, minmax(120px, 0.5fr));
  }
`;

const TabbarContainer = () => {

  const { t } = useTranslation(['component']);

  const theme = useTheme();
  const buttonRef = useRef();
  const [hubMenuOpen, setHubMenuOpen] = useState(false);
  const [addMenuOpen, setAddMenuOpen] = useState(false);
  const [hidden, setHidden] = useState(false);

  // const showAddActivity = challengeFeature.enabled;
  const showAddActivity = false; // Disabled due to the form being broken and no time to fix

  const { channelId: postCreateChannelId } = useSmartPostCreateInitialValues();

  const { hubItems } = useNavigationItems();

  useEffect(() => {
    return history.listen(() => {
      setAddMenuOpen(false);
      setHubMenuOpen(false);
    });
  }, []);

  useEffect(() => {
    const fnSet = () => setHidden(true);
    const fnRemove = () => setHidden(false);

    window.addEventListener('keyboardWillShow', fnSet, { passive: true });
    window.addEventListener('keyboardWillHide', fnRemove, { passive: true });
    return () => {
      window.removeEventListener('keyboardWillShow', fnSet, { passive: true });
      window.removeEventListener('keyboardWillHide', fnRemove, { passive: true });
    };
  }, []);

  const onHubButtonClick = () => {
    setHubMenuOpen(c => !c);
    setAddMenuOpen(false);
  };

  const onAddButtonClick = () => {
    setAddMenuOpen(c => !c);
    setHubMenuOpen(false);
  };


  if (hidden && !hubMenuOpen) {
    return null;
  }

  const tabButtons = hubItems.slice(0, 3);
  const hubButtons = hubItems.slice(3);

  // Without any hub icons, there will be just search functionality
  const HubIcon = hubButtons.length > 0 ? AppsRoundedIcon : SearchRoundedIcon;

  // Without any hub icons, there's no room below the search bar
  const searchProps = hubButtons.length > 0 ? {} : {
    popperPlacement: 'top',
    reverseOptions: true,
  };

  return (
    <>

      {addMenuOpen && <NavbarBackdrop open={addMenuOpen} />}

      <Hub
        open={hubMenuOpen}
        onClose={() => setHubMenuOpen(false)}
      >
        {/* Disable autocomplete for search here? */}
        <Grid container spacing={2} direction="column" wrap="nowrap">
          <Grid item>
            <Search {...searchProps} />
          </Grid>
          <Grid item>
            <GridWithActualGrid container spacing={2}>

              {hubButtons.map(({ icon: Icon, children, ...args }) => (
                <Grid item key={args.to}>
                  <HubButton as={NavLink} {...args} icon={<Icon />}>{children}</HubButton>
                </Grid>
              ))}

            </GridWithActualGrid>
          </Grid>
        </Grid>
      </Hub>

      <Tabbar>

        {tabButtons.map(({ icon: Icon, children, ...args }) => (
          <Tab key={args.to} isActive={match => match && !hubMenuOpen} component={NavLink} {...args}><Icon fontSize="inherit" /></Tab>
        ))}

        <Tab onClick={onHubButtonClick}><HubIcon fontSize="inherit" /></Tab>

        <SpecialTab onClick={onAddButtonClick} ref={buttonRef}>
          <PlusRoundedIcon />
        </SpecialTab>

        <Menu
          open={addMenuOpen}
          onClose={() => setAddMenuOpen(false)}
          anchorEl={buttonRef.current}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}

          slotProps={{
            root: {
              slotProps: {
                backdrop: {
                  sx: {
                    zIndex: theme.zIndex.hubBackdrop,
                  },
                },
              },
            },
            paper: {
              sx: {
                zIndex: theme.zIndex.hubDrawer,
              },
            },
          }}

          keepMounted
          disableAutoFocusItem
        >
          <MenuItem onClick={() => history.push(`/post/create${postCreateChannelId ? `?channelId=${postCreateChannelId}` : ''}`)}>
            <ListItemIcon><CommentIcon /></ListItemIcon>
            <ListItemText>{t('tabbarActions.createNewPost')}</ListItemText>
          </MenuItem>
          {showAddActivity && (
            <MenuItem onClick={() => history.push('/activity/create')}>
              <ListItemIcon><TrophyIcon /></ListItemIcon>
              <ListItemText>{t('tabbarActions.createNewEntry')}</ListItemText>
            </MenuItem>
          )}
        </Menu>
      </Tabbar>
    </>
  );
};

export default TabbarContainer;
