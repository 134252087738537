import React, { useMemo } from 'react';
import { ThemeProvider as MuiThemeProvider, StyledEngineProvider } from '@mui/material/styles';

import { useSelector, Provider as ReduxProvider } from 'react-redux';
import { Router } from 'react-router';

import ConfirmationProvider from 'components/Confirmation/ConfirmationProvider';
import { I18nextProvider, useTranslation } from 'react-i18next';
import i18n from 'providers/i18n';

import store from 'redux/store';
import history from 'providers/history';
import GlobalStyle from 'providers/globalStyle';
import createTheme from 'providers/createTheme';
import { authSelectors } from 'redux/auth';
import SnackbarProvider from 'providers/SnackbarProvider';
import IntercomProvider from 'providers/intercom';
import ScrollToTop from 'components/ScrollToTop';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { QueryParamProvider } from 'use-query-params';
import { getLocale } from 'services/date-fns';
import { parse, stringify } from 'query-string';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';
import ZendeskProvider from 'providers/ZendeskProvider';
import ChatProvider from 'providers/ChatProvider';
import LayoutProvider from './Layouts/LayoutProvider';


const queryParamProviderOptions = {
  searchStringToObject: parse,
  objectToSearchString: stringify,
};

const ThemeProviders = ({ children }) => {

  const { i18n: { language } } = useTranslation();

  const color = useSelector(authSelectors.getWorkspaceColor);
  const theme = useMemo(() => createTheme({ color, isDark: false, language }), [color, language]);

  return (
    <StyledEngineProvider injectFirst>
      {/* Set themes for material-ui & @emotion */}
      <MuiThemeProvider theme={theme}>
        {/* Inject global styles */}
        <GlobalStyle />
        {children}
      </MuiThemeProvider>
    </StyledEngineProvider>
  );
};

const DatePickerLocalizationProvider = args => {

  // Needed to trigger re-renders on language change
  const { i18n: { language } } = useTranslation();

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={getLocale(language)}
      {...args}
    />
  );
};

const Providers = ({ children }) => {

  return (
    <I18nextProvider i18n={i18n}>
      {/* Initiate redux store */}
      <ReduxProvider store={store}>

        {/* Configure all theme related stuff */}
        <ThemeProviders>

          <LayoutProvider>

            {/* Activate Router */}
            <Router history={history}>

              <QueryParamProvider
                adapter={ReactRouter5Adapter}
                options={queryParamProviderOptions}
              >

                <DatePickerLocalizationProvider>
                  <SnackbarProvider>

                    <ConfirmationProvider>
                      <IntercomProvider>
                        <ZendeskProvider>
                          <ChatProvider>
                            <ScrollToTop>
                              {children}
                            </ScrollToTop>
                          </ChatProvider>
                        </ZendeskProvider>
                      </IntercomProvider>
                    </ConfirmationProvider>

                  </SnackbarProvider>
                </DatePickerLocalizationProvider>

              </QueryParamProvider>

            </Router>

          </LayoutProvider>

        </ThemeProviders>
      </ReduxProvider>
    </I18nextProvider>
  );
};

export default Providers;
