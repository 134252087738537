import React from 'react';
import { Global, css, useTheme } from '@emotion/react';
import { normalize } from 'polished';
import { isMobile, isWeb } from 'helpers';

const GlobalStyle = () => {

  const reset = normalize();
  const theme = useTheme();

  return (
    <Global styles={css`

      ${reset}

      :root {
        --navbar-height: 52px;
      }

      html {
        box-sizing: border-box;
        font-size: ${isMobile ? '87.5%' : '93.75%'};
        height: 100%;
      }

      *, *::before, *::after {
        box-sizing: inherit;
      }

      html, body {
        margin: 0;
        padding: 0;
      }

      ${!isMobile && css`
        body, #root {
          min-height: 100vh;
        }
      `}

      body {
        font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        background-color: #F9F9F9;
        color: #333;
        line-height: 1.3;

        &,
        & .u-safe-bottom-padding {
          padding-bottom: 1rem;
          padding-bottom: calc(constant(safe-area-inset-bottom) + 1rem);
          padding-bottom: calc(env(safe-area-inset-bottom, 0) + 1rem);
        }

        & .u-safe-bottom-padding-tight {
          padding-bottom: 0rem;
          padding-bottom: calc(constant(safe-area-inset-bottom) + 0rem);
          padding-bottom: calc(env(safe-area-inset-bottom, 0) + 0rem);
        }

        .has-mobile-tabbar &,
        .has-mobile-tabbar & .u-tabbar-bottom-padding {
          padding-bottom: calc(56px + 1rem);
          padding-bottom: calc(56px + constant(safe-area-inset-bottom) + 1rem);
          padding-bottom: calc(56px + env(safe-area-inset-bottom, 0) + 1rem);
        }

        .has-mobile-tabbar & .u-tabbar-bottom-padding-tight {
          padding-bottom: calc(56px + 0rem);
          padding-bottom: calc(56px + constant(safe-area-inset-bottom) + 0rem);
          padding-bottom: calc(56px + env(safe-area-inset-bottom, 0) + 0rem);
        }

        .has-mobile-navbar & {
          padding-top: 52px;
          padding-top: var(--navbar-height);
        }

        .has-bottom-input-bar & {
          padding-bottom: calc(48px + 1rem);
          padding-bottom: calc(48px + constant(safe-area-inset-bottom) + 1rem);
          padding-bottom: calc(48px + env(safe-area-inset-bottom, 0) + 1rem);
        }
      }

      ::selection {
        background: ${theme.palette.primary.main};
        color: ${theme.palette.primary.contrastText};
      }

      .mui-fixed.fc-widget-normal {
        box-sizing: content-box;
        user-select: none;
      }

      /* Below this line is cordova only */
      ${isMobile && css`
        * {
          user-select: none;
          -webkit-tap-highlight-color: transparent;
          -webkit-touch-callout: none;
        }

        .user-selectable, .user-selectable * {
          user-select: auto;
          -webkit-tap-highlight-color: initial;
          -webkit-touch-callout: initial;
        }

        input, textarea {
          touch-action: manipulation;
          user-select: auto !important;
        }

        &::-webkit-scrollbar {
          width: 0px;
          height: 0px;
          background: transparent; /* make scrollbar transparent */
        }
      `}

      ${isWeb && css`
        body {
          overflow-y: scroll;
        }

        .intercom-lightweight-app, .intercom-app > iframe, .intercom-app > div {
          z-index: ${theme.zIndex.helpFloat} !important;
        }

        .intercom-messenger-frame {
          &&& {
            bottom: 20px;
            right: 84px;
          }
        }
      `}
    `}
    />
  );
};

export default GlobalStyle;
