export default {
  getToken: 'chat/GET_TOKEN',
  getTokenSuccess: 'chat/GET_TOKEN_SUCCESS',
  getTokenFailure: 'chat/GET_TOKEN_FAILURE',

  createConversation: 'chat/CREATE_CONVERSATION',
  createConversationSuccess: 'chat/CREATE_CONVERSATION_SUCCESS',
  createConversationFailure: 'chat/CREATE_CONVERSATION_FAILURE',

  getConversation: 'chat/GET_CONVERSATION',
  getConversationSuccess: 'chat/GET_CONVERSATION_SUCCESS',
  getConversationFailure: 'chat/GET_CONVERSATION_FAILURE',

  getConversations: 'chat/GET_CONVERSATIONS',
  getConversationsSuccess: 'chat/GET_CONVERSATIONS_SUCCESS',
  getConversationsFailure: 'chat/GET_CONVERSATIONS_FAILURE',

  markConversationUnread: 'chat/MARK_CONVERSATION_UNREAD',
  markConversationUnreadSuccess: 'chat/MARK_CONVERSATION_UNREAD_SUCCESS',
  markConversationUnreadFailure: 'chat/MARK_CONVERSATION_UNREAD_FAILURE',

  updateConversation: 'chat/UPDATE_CONVERSATION',
  updateConversationSuccess: 'chat/UPDATE_CONVERSATION_SUCCESS',
  updateConversationFailure: 'chat/UPDATE_CONVERSATION_FAILURE',

  getConversableMembers: 'chat/GET_CONVERSABLE_MEMBERS',
  getConversableMembersSuccess: 'chat/GET_CONVERSABLE_MEMBERS_SUCCESS',
  getConversableMembersFailure: 'chat/GET_CONVERSABLE_MEMBERS_FAILURE',

  getMembersForConversation: 'chat/GET_MEMBERS_FOR_CONVERSATION',
  getMembersForConversationSuccess: 'chat/GET_MEMBERS_FOR_CONVERSATION_SUCCESS',
  getMembersForConversationFailure: 'chat/GET_MEMBERS_FOR_CONVERSATION_FAILURE',

  conversationMemberDelete: 'chat/CONVERSATION_MEMBER_DELETE',
  conversationMemberDeleteSuccess: 'chat/CONVERSATION_MEMBER_DELETE_SUCCESS',
  conversationMemberDeleteFailure: 'chat/CONVERSATION_MEMBER_DELETE_FAILURE',

  conversationMemberAdd: 'chat/CONVERSATION_MEMBER_ADD',
  conversationMemberAddSuccess: 'chat/CONVERSATION_MEMBER_ADD_SUCCESS',
  conversationMemberAddFailure: 'chat/CONVERSATION_MEMBER_ADD_FAILURE',
};
