import styled from '@emotion/styled/macro';

const DrawerBody = styled('div')`
  min-height: 0px; /* To allow shrinking */
  height: 100%; /* Take as much space as possible */

  width: 100%;

  overflow-y: auto;
`;

export default DrawerBody;
