import React from 'react';
import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';
import useFeature from 'hooks/useFeature';

const StyledSidebarWrapper = styled('div')`
  display: flex;
  flex-direction: column;

  ${({ hasIntercom }) => hasIntercom && css`
    padding-bottom: 80px;
  `}
`;

const SidebarWrapper = React.forwardRef((args, ref) => {
  const intercomFeature = useFeature('intercom');
  const intercomLeft = window.intercomSettings?.alignment === 'left';

  const hasIntercom = intercomFeature.enabled && intercomLeft;

  return (
    <StyledSidebarWrapper
      ref={ref}
      hasIntercom={hasIntercom}
      {...args}
    />
  );
});

export default SidebarWrapper;
