import MuiDrawer from '@mui/material/Drawer';
import styled from '@emotion/styled/macro';

const Drawer = styled(MuiDrawer)`
  display: flex;
  flex-direction: column;

  .MuiPaper-root {
    min-width: min(350px, 90vw);
    max-width: 90vw;

    @media (max-width: 440px) {
      max-width: 100vw;
    }
  }
`;

Drawer.propTypes = MuiDrawer.propTypes;

export default Drawer;
