import React from 'react';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'propTypes';
import MessagingIcon from 'components/Icons/Messaging';
import { useSelector } from 'react-redux';
import { authSelectors } from 'redux/auth';
import useChat from 'hooks/useChat';
import { chatTypes } from 'redux/chat';
import { promiseListener } from 'redux/store';

const { asyncFunction: createConversation } = promiseListener.createAsyncFunction({
  start: chatTypes.createConversation,
  resolve: chatTypes.createConversationSuccess,
  reject: chatTypes.createConversationFailure,
});

const ChatQuickAction = ({ memberId, preventDefault, ...rest }) => {

  const { onShowConversation } = useChat();

  const authMemberId = useSelector(authSelectors.getMemberId);

  const onClick = (e) => {
    createConversation({
      memberIds: [memberId, authMemberId],
      isDirect: true,
    })
      .then(({ data }) => {
        onShowConversation(data);
      });

    if (preventDefault) {
      e.preventDefault();
    }
  };

  return (
    <IconButton onClick={onClick} {...rest}>
      <MessagingIcon />
    </IconButton>
  );
};

ChatQuickAction.propTypes = {
  memberId: PropTypes.any.isRequired,
  preventDefault: PropTypes.bool,
};

export default ChatQuickAction;
