import React, { useContext, useEffect, useMemo, useState } from 'react';
import MobileBottomDrawer from 'components/MobileBottomDrawer';
import MaxWidthContainer from 'components/MaxWidthContainer';
import { Inbox } from '@talkjs/react';
import { useTheme } from '@emotion/react';
import { isMobile } from 'helpers';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { chatActions, chatSelectors } from 'redux/chat';
import { ChatContext } from 'providers/ChatProvider';
import Grid from 'components/Grid';
import Button from '@mui/material/Button';
import CreateRoundedIcon from '@mui/icons-material/CreateRounded';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import styled from '@emotion/styled/macro';
import useMediaQuery from '@mui/material/useMediaQuery';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import PropTypes from 'prop-types';
import CreateConversationForm from './CreateConversationForm';

const DrawerContent = styled('div')`
  height: 100%;

  margin-top: 1rem;

  padding-left: ${({ theme }) => theme.spacing(1)};
  padding-right: ${({ theme }) => theme.spacing(1)};
`;

const Wrapper = styled('div')`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const Top = styled('div')`
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

const Bottom = styled('div')`
  height: 100%;
`;


const ChatBottomDrawer = ({ onClose, ...rest }) => {

  const theme = useTheme();
  const dispatch = useDispatch();
  const { onManageMembers, onManageConversation } = useContext(ChatContext);
  const { t } = useTranslation('chat');

  // Configured on TalkJS. Not easily configurable.
  const twoColumnLayout = useMediaQuery('(min-width: 640px)');

  const [mode, setMode] = useState('view');

  const [selected, setSelected] = useState(null);

  const getConversationSelector = useSelector(chatSelectors.getConversationSelector);

  useEffect(() => {
    if (!selected || !!getConversationSelector(selected)) {
      return;
    }
    dispatch(chatActions.getConversation({ conversationId: selected }));
  }, [dispatch, getConversationSelector, selected]);

  const onCreateSuccess = ({ data: conversationId }) => {
    setMode('view');
    setSelected(conversationId);
  };

  const inboxTheme = useMemo(() => ({
    name: 'guavahr',
    custom: {
      primaryColor: theme.palette.primary.main,
      primaryColorDark: theme.palette.primary.dark,
      primaryColorLight: theme.palette.primary.light,
      primaryContrastText: theme.palette.primary.contrastText,

      displayManageMembers: getConversationSelector(selected)?.permissions?.manageMembers ? 'block' : 'none', // display: none|block;
      displayManageConversation: getConversationSelector(selected)?.permissions?.manage ? 'block' : 'none', // display: none|block;
      displayToggleSize: isMobile ? 'none' : 'block', // display: none|block;
      displayMinimize: isMobile ? 'none' : 'flex', // display: none|flex
      displayBack: isMobile && !twoColumnLayout ? 'block' : 'none', // display: none|block

      chatboxBorderRadius: 0,

      // IMPORTANT! Translations need quotes around the strings!
      viewMembersTranslation: `"${t('actionMenu.viewMembers')}"`,
      toggleSizeTranslation: `"${t('actionMenu.toggleSize')}"`,
      manageConversationTranslation: `"${t('actionMenu.manageConversation')}"`,
    },
  }), [getConversationSelector, selected, t, theme.palette.primary.contrastText, theme.palette.primary.dark, theme.palette.primary.light, theme.palette.primary.main, twoColumnLayout]);

  const onConversationSelected = e => {
    setSelected(e?.conversation?.id ?? null);
  };

  const onMarkConversationAsUnread = (e) => {
    if (!e.conversation?.id) {
      e?.preventDefault();
      return false;
    }
    dispatch(chatActions.markConversationUnread({ conversationId: e.conversation.id }));
    setSelected(null);
  };

  const onCustomConversationAction = (e) => {
    if (e.action === 'view_members') {
      onManageMembers(e.conversation.id);
    }
    if (e.action === 'manage_conversation') {
      onManageConversation(e.conversation.id);
    }
    if (e.action === 'back') {
      setMode('view');
      setSelected(null);
    }
  };

  const onCreateClick = () => {
    setMode('create');
    setSelected(null);
  };

  const onViewClick = () => {
    setMode('view');
  };

  return (
    <MobileBottomDrawer
      disableHandlebarPadding={!!selected && !twoColumnLayout}
      ModalProps={{
        sx: {
          zIndex: theme.zIndex.chatBackdrop,
        },
      }}
      PaperProps={{
        className: 'u-safe-bottom-padding-tight',
        sx: {
          zIndex: theme.zIndex.chatDrawer,

          height: '100%',
          maxHeight: 'calc(100vh - 20px)',
          // borderTopRightRadius: 0,
          // borderTopLeftRadius: 0,
        },
      }}

      onClose={onClose}
      {...rest}
    >
      <Wrapper>

        {(!selected || twoColumnLayout) && (
          <Top>
            <MaxWidthContainer>
              <Grid container direction="row" wrap="nowrap" justifyContent="space-between" alignItems="center">
                <Grid item>
                  {mode === 'create' && (
                    <Button variant="text" size="small" startIcon={<ArrowBackIosRoundedIcon />} edge="start" onClick={onViewClick}>{t('chat:drawer.back')}</Button>
                  )}
                  {mode === 'view' && (
                    <Button variant="text" size="small" startIcon={<CloseRoundedIcon />} edge="start" onClick={onClose}>{t('chat:drawer.close')}</Button>
                  )}
                </Grid>
                <Grid item>
                  {mode === 'view' && (
                    <Button variant="text" size="small" startIcon={<CreateRoundedIcon />} edge="end" onClick={onCreateClick}>{t('chat:drawer.createNewChat')}</Button>
                  )}
                </Grid>
              </Grid>
            </MaxWidthContainer>
          </Top>
        )}

        <Bottom>
          <MaxWidthContainer sx={{ height: '100%' }} disableGutters={!!selected || !twoColumnLayout}>
            {mode === 'view'
              ? (
                <Inbox
                  showChatHeader
                  showFeedHeader={false}
                  showMobileBackButton={false}

                  selected={selected}
                  onConversationSelected={onConversationSelected}
                  onMarkConversationAsUnread={onMarkConversationAsUnread}
                  onCustomConversationAction={onCustomConversationAction}

                  theme={inboxTheme}

                  style={{ height: '100%', width: '100%' }}
                />
              )
              : (
                <DrawerContent>
                  <CreateConversationForm
                    onSuccess={onCreateSuccess}
                  />
                </DrawerContent>
              )}
          </MaxWidthContainer>
        </Bottom>
      </Wrapper>
    </MobileBottomDrawer>
  );
};

ChatBottomDrawer.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default ChatBottomDrawer;
