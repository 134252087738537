import React from 'react';
import { NavLink as MuiNavLink, useRouteMatch } from 'react-router-dom';

const NavLink = React.forwardRef(({ replace, to, ...rest }, ref) => {

  const match = useRouteMatch(to);

  const computedReplace = replace ?? (to && match?.isExact);

  return (
    <MuiNavLink replace={computedReplace} to={to || '#'} ref={ref} {...rest} />
  );
});

NavLink.displayName = MuiNavLink.displayName;
NavLink.propTypes = MuiNavLink.propTypes;

export default NavLink;
