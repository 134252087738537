import React, { useContext, useEffect } from 'react';
import Collapse from '@mui/material/Collapse';
import styled from '@emotion/styled/macro';
import { useTranslation } from 'react-i18next';
import Grid from 'components/Grid';
import ManageContactsButton from 'containers/ContactDirectory/ManageContactsButton';
import { useSelector } from 'react-redux';
import { authSelectors } from 'redux/auth';
import useContactDirectoryQueryParams from 'containers/Side/ContactDirectory/useContactDirectoryQueryParams';
import PropTypes from 'propTypes';
import SideContext from '../SideContext';
import DrawerBody from '../Drawer/DrawerBody';

const ColumnsBody = styled('div')`
  min-height: 0px; /* To allow shrinking */
  height: 100%; /* Take as much space as possible */

  width: 100%;

  display: flex;
  flex-direction: row;
`;

const LeftColumn = styled(DrawerBody)`
  width: 350px;
  padding-bottom: 20vh;
`;

const RightColumn = styled('div')`
  width: 400px;
  border-left: 1px solid #f5f5f5;

  min-height: 0px; /* To allow shrinking */
  height: 100%; /* Take as much space as possible */

  overflow-y: auto;
`;

const ContactDirectoryWide = ({ list, item }) => {

  const { t } = useTranslation(['component']);

  const { setTitle } = useContext(SideContext);

  const hasPermission = useSelector(authSelectors.hasPermissionSelector);

  const { onSideCloseClick, onManageContactsToggleClick, isViewMode, isContactManageMode, isContactCreateMode } = useContactDirectoryQueryParams();

  useEffect(() => {
    setTitle({
      children: (
        <Grid container direction="row" wrap="nowrap" spacing={1} alignItems="center" justifyContent="space-between">
          <Grid item>{t('component:contactDirectory.title')}</Grid>
          {hasPermission('MANAGE_PEOPLE_DIRECTORY') && (
            <Grid item>
              <ManageContactsButton isManageMode={isContactManageMode} onClick={onManageContactsToggleClick} />
            </Grid>
          )}
        </Grid>
      ),
      backButton: { onClick: onSideCloseClick },
    });
  }, [hasPermission, isContactManageMode, onManageContactsToggleClick, onSideCloseClick, setTitle, t]);

  return (
    <ColumnsBody>
      <LeftColumn>
        {list}
      </LeftColumn>
      <Collapse orientation="horizontal" in={isViewMode || isContactCreateMode}>
        <RightColumn>
          {item}
        </RightColumn>
      </Collapse>
    </ColumnsBody>
  );
};

ContactDirectoryWide.propTypes = {
  list: PropTypes.any,
  item: PropTypes.any,
};

export default ContactDirectoryWide;
