import React, { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { workspaceActions, workspaceSelectors, workspaceTypes } from 'redux/workspace';
import Grid from 'components/Grid';
import Avatar from 'components/Avatar';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import styled from '@emotion/styled/macro';
import { isMobile } from 'helpers';
import useReduxLoading from 'hooks/useReduxLoading';
import TextLoader from 'components/TextLoader';
import MemberQuickActions from './Member/QuickActions/MemberQuickActions';

const Wrapper = styled('span')`
  display: inline-block;
`;

const StyledPopper = styled(Popper)`
  z-index: ${({ theme }) => theme.zIndex.overflowMenu};

  padding-top: 0.25rem;
`;

const StyledPaper = styled(Paper)`
  max-width: 400px;
  font-size: 0.875rem;
  padding: ${({ theme }) => theme.spacing(1, 2)};
`;

const Name = styled('div')`
  font-weight: 500;
`;

const Job = styled('div')`
  color: #888;
`;


const MemberContent = ({ memberId }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(workspaceActions.getMember({ memberId }));
  }, [dispatch, memberId]);

  const member = useSelector(workspaceSelectors.getMemberSelector)(memberId);

  const loading = useReduxLoading(
    [workspaceTypes.getMember],
    [workspaceTypes.getMemberSuccess, workspaceTypes.getMemberFailure],
    true,
    memberId,
  );

  if (loading) {
    return <TextLoader />;
  }

  return (
    <Grid container direction="column" wrap="nowrap" spacing={1}>
      <Grid item>
        <Grid container direction="row" alignItems="center" wrap="nowrap" spacing={2}>
          <Grid item>
            <Avatar size={32} src={member?.avatar?.src} name={member?.name} />
          </Grid>
          <Grid item xs>
            <Grid container direction="column" wrap="nowrap" spacing={0.5}>
              <Grid item><Name>{member?.name}</Name></Grid>
              {member?.job && <Grid item><Job>{member?.job}</Job></Grid>}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <MemberQuickActions
          contactNumber={member?.contactNumber}
          contactEmail={member?.contactEmail}
          profileMemberId={memberId}
          chatMemberId={member?.permissions?.chat ? memberId : null}
        />
      </Grid>
    </Grid>
  );
};

MemberContent.propTypes = {
  memberId: PropTypes.any,
};

const popoverDelay = 500;
const MemberPopover = ({ memberId, ...rest }) => {

  const anchorEl = useRef();
  const [open, setOpen] = useState(false);

  const timeoutRef = useRef();

  if (!memberId) {
    return <React.Fragment {...rest} />;
  }

  const onMouseEnter = () => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => setOpen(true), popoverDelay);
  };

  const onMouseLeave = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setOpen(false);
  };

  const onClick = (e) => {
    if (!isMobile) {
      return;
    }

    // On mobile. Initial click. Open the popover and ignore everything else
    if (!open) {
      setOpen(true);
      e.preventDefault();
      e.stopPropagation();
    }

    setOpen(false);
    // Proceed with default onClick action if one is set outside of this component.
  };

  return (
    <Wrapper onMouseLeave={onMouseLeave}>
      <Box ref={anchorEl} onMouseEnter={onMouseEnter} onClick={onClick} {...rest} />
      <ClickAwayListener onClickAway={onMouseLeave}>
        <StyledPopper open={open} anchorEl={anchorEl.current} transition placement="bottom-start">
          {({ TransitionProps }) => (
            <Fade {...TransitionProps}>
              <StyledPaper>
                <MemberContent memberId={memberId} />
              </StyledPaper>
            </Fade>
          )}
        </StyledPopper>
      </ClickAwayListener>
    </Wrapper>
  );
};

MemberPopover.propTypes = {
  memberId: PropTypes.any,
};

export default MemberPopover;
