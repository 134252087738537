import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

function ScrollToTop(args) {
  const history = useHistory();

  useEffect(() => {
    return history.listen((nextLocation) => {

      if (nextLocation.state?.disableScrollToTop) {
        return; // Skip scrolling if disableScrollToTop is true in the state
      }

      if (document.documentElement.classList.contains('disable-scroll-to-top')) {
        return; // Skip scrolling if the class is present
      }

      window.scrollTo(0, 0);
    });
  }, [history]);

  return <React.Fragment {...args} />;
}

export default ScrollToTop;
