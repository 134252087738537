import { useCallback, useMemo, useState } from 'react';

function useLocalStorage(key, initialValue) {
  // Retrieve the value from localStorage or use the initialValue
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(`Error reading localStorage key "${key}":`, error);
      return initialValue;
    }
  });

  // Create a function to update the localStorage value and state
  const setValue = useCallback((value) => {
    try {
      setStoredValue(current => {
        const nextValue = value instanceof Function ? value(current) : value;

        window.localStorage.setItem(key, JSON.stringify(nextValue));

        return nextValue;
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(`Error setting localStorage key "${key}":`, error);
    }
  }, [key]);

  return useMemo(() => [storedValue, setValue], [setValue, storedValue]);
}

export default useLocalStorage;
