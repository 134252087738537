import styled from '@emotion/styled/macro';
import Box from '@mui/material/Box';
import { isMobile } from 'helpers';

const PaperSeparator = styled(Box)`

  width: 100%;
  margin: ${isMobile ? '1rem 0' : '1.5rem 0'};
  height: ${isMobile ? '1px' : '2px'};

  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: ${isMobile ? '1px' : '2px'};
    background-color: #EEEEEE;
  }
`;

export default PaperSeparator;
