import React, { useEffect } from 'react';
import Divider from '@mui/material/Divider';
import Grid from 'components/Grid';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { memberSelectors } from 'redux/member';
import Header from 'components/Header';
import useWorkspaceCover from 'hooks/useWorkspaceCover';
import CopyableTextField from 'containers/ContactDirectory/Item/Partials/CopyableTextField';
import MemberQuickActions from 'containers/Member/QuickActions/MemberQuickActions';
import { workspaceActions } from 'redux/workspace';
import PropTypes from 'propTypes';

const ContactDirectoryDataMember = ({ memberId, ContentWrapper }) => {

  const dispatch = useDispatch();

  const workspaceCover = useWorkspaceCover();

  useEffect(() => {
    dispatch(workspaceActions.getMember({ memberId }));
  }, [dispatch, memberId]);

  const member = useSelector(memberSelectors.getMemberSelector)(memberId);

  const { t } = useTranslation(['component']);

  return (
    <>
      <Header name={member?.name} avatar={member?.avatar} cover={member?.cover || workspaceCover} />

      <Grid container justifyContent="space-between" alignItems="center" wrap="nowrap">
        <Grid item xs>
          <ContentWrapper>
            <MemberQuickActions
              contactEmail={member?.contactEmail}
              contactNumber={member?.contactNumber}
              profileMemberId={member?.id}
              chatMemberId={member?.permissions?.chat ? member?.id : null}
            />
          </ContentWrapper>
        </Grid>
      </Grid>

      <ContentWrapper>
        <Grid container spacing={2} direction="column" wrap="nowrap">
          {member?.contactNumber && (
            <Grid item>
              <CopyableTextField label={t('contactDirectory.itemInfo.contactNumberLabel')} value={member.contactNumber} />
            </Grid>
          )}

          {member?.contactEmail && (
            <Grid item>
              <CopyableTextField label={t('contactDirectory.itemInfo.emailLabel')} value={member.contactEmail} />
            </Grid>
          )}

          {member?.job && (
            <Grid item>
              <CopyableTextField label={t('contactDirectory.itemInfo.joblLabel')} value={member.job} />
            </Grid>
          )}

          {member?.structuralUnit && (
            <Grid item>
              <CopyableTextField label={t('contactDirectory.itemInfo.structuralUnitLabel')} value={member.structuralUnit} />
            </Grid>
          )}

          {member?.info && (
            <>
              <Grid item><Divider light /></Grid>
              <Grid item sx={{ whiteSpace: 'pre-wrap' }}>{member?.info}</Grid>
            </>
          )}
        </Grid>
      </ContentWrapper>
    </>
  );
};

ContactDirectoryDataMember.propTypes = {
  memberId: PropTypes.any,
  ContentWrapper: PropTypes.elementType,
};

export default ContactDirectoryDataMember;
