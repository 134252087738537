import React from 'react';
import MaxWidthContainer from 'components/MaxWidthContainer';
import PropTypes from 'prop-types';
import MobileBottomDrawer from 'components/MobileBottomDrawer';
import { useTheme } from '@emotion/react';


const Hub = ({ children, open, onClose, ...args }) => {

  const theme = useTheme();

  return (
    <MobileBottomDrawer
      open={open}
      onOpen={() => {}}
      onClose={onClose}

      ModalProps={{
        sx: {
          zIndex: theme.zIndex.hubBackdrop,
        },
      }}
      PaperProps={{
        sx: {
          zIndex: theme.zIndex.hubDrawer,
        },
      }}
      {...args}
    >
      <MaxWidthContainer>
        {children}
      </MaxWidthContainer>
    </MobileBottomDrawer>
  );
};

Hub.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Hub;
