import { createAction } from 'redux/helpers/actions';
import types from './types';

const actions = {
  login: data => createAction(types.login, data),
  loginSuccess: data => createAction(types.loginSuccess, data),
  loginFailure: error => createAction(types.loginFailure, error),

  refreshToken: () => createAction(types.refreshToken),
  refreshTokenSuccess: data => createAction(types.refreshTokenSuccess, data),
  refreshTokenFailure: error => createAction(types.refreshTokenFailure, error),

  logout: () => createAction(types.logout),
  logoutSuccess: () => createAction(types.logoutSuccess),
  logoutFailure: error => createAction(types.logoutFailure, error),

  deleteAccount: data => createAction(types.deleteAccount, data),
  deleteAccountSuccess: data => createAction(types.deleteAccountSuccess, data),
  deleteAccountFailure: data => createAction(types.deleteAccountFailure, data),

  sendForgotEmail: (data, config) => createAction(types.sendForgotEmail, data, config),
  resetPassword: (data, config) => createAction(types.resetPassword, data, config),

  verifyToken: ({ token }, config) => createAction(types.verifyToken, { token }, config),

  register: (data, config) => createAction(types.register, data, config),
  join: (data, config) => createAction(types.join, data, config),

  getProfile: () => createAction(types.getProfile),
  getProfileSuccess: data => createAction(types.getProfileSuccess, data),
  getProfileFailure: data => createAction(types.getProfileFailure, data),

  getZendeskToken: () => createAction(types.getZendeskToken),
  getZendeskTokenSuccess: data => createAction(types.getZendeskTokenSuccess, data),
  getZendeskTokenFailure: data => createAction(types.getZendeskTokenFailure, data),
};

export default actions;
