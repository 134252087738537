import { createAction } from 'redux/helpers/actions';
import types from './types';

export default {
  getToken: (data) => createAction(types.getToken, data),
  getConversation: (data) => createAction(types.getConversation, data),
  getConversations: (data) => createAction(types.getConversations, data),
  updateConversation: (data) => createAction(types.updateConversation, data),
  createConversation: (data) => createAction(types.createConversation, data),
  markConversationUnread: (data) => createAction(types.markConversationUnread, data),

  getConversableMembers: (data) => createAction(types.getConversableMembers, data),
  getMembersForConversation: (data) => createAction(types.getMembersForConversation, data),

  conversationMemberDelete: (data) => createAction(types.conversationMemberDelete, data),
  conversationMemberAdd: (data) => createAction(types.conversationMemberAdd, data),
};
