import React from 'react';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { useTheme, css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import PropTypes from 'propTypes';

const styledSwipeableDrawerConfig = {
  shouldForwardProp: prop => !['disableHandlebar', 'disableHandlebarPadding'].includes(prop),
};
const StyledSwipeableDrawer = styled(SwipeableDrawer, styledSwipeableDrawerConfig)`

  ${({ disableHandlebar, disableHandlebarPadding }) => !disableHandlebar && css`
    & .MuiDrawer-paper::before {
      content: '';
      display: block;
      position: absolute;

      top: 0.5rem;
      left: 0;
      right: 0;
      margin: 0 auto;

      width: 2rem;
      height: 4px;
      border-radius: 4px;
      background-color: #ddd;
      z-index: 1;
    }

    & .MuiDrawer-paper {
      padding-top: ${disableHandlebarPadding ? 0 : '1.5rem'};
    }
  `}
`;

const MobileBottomDrawer = ({ ModalProps, PaperProps, disableHandlebar, disableHandlebarPadding, ...rest }) => {

  const theme = useTheme();

  return (
    <StyledSwipeableDrawer
      disableSwipeToOpen
      disableDiscovery

      disableHandlebar={disableHandlebar}
      disableHandlebarPadding={disableHandlebarPadding}

      anchor="bottom"
      ModalProps={{
        ...ModalProps,
        sx: {
          zIndex: theme.zIndex.tabbarBackdrop,
          ...ModalProps?.sx,
        },
      }}
      PaperProps={{
        className: 'u-tabbar-bottom-padding',
        ...PaperProps,
        sx: {
          zIndex: theme.zIndex.tabbarDrawer,
          borderTopLeftRadius: '16px',
          borderTopRightRadius: '16px',
          ...PaperProps?.sx,
        },
      }}
      {...rest}
    />
  );
};

MobileBottomDrawer.propTypes = {
  ModalProps: PropTypes.object,
  PaperProps: PropTypes.object,

  disableHandlebar: PropTypes.bool,
  disableHandlebarPadding: PropTypes.bool,
};

export default MobileBottomDrawer;
