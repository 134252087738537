import React, { useEffect, useState } from 'react';
import Divider from '@mui/material/Divider';
import Grid from 'components/Grid';
import { useTranslation } from 'react-i18next';
import FormField from 'components/FinalForm/Fields/FormField';
import Button from 'components/Button';
import EditIcon from 'components/Icons/Edit';
import TrashIcon from 'components/Icons/Trash';
import Form from 'components/FinalForm/Form';
import CopyableTextField from 'containers/ContactDirectory/Item/Partials/CopyableTextField';
import { useDispatch, useSelector } from 'react-redux';
import { contactActions, contactSelectors, contactTypes } from 'redux/contact';
import useWorkspaceCover from 'hooks/useWorkspaceCover';
import Header from 'components/Header';
import PropTypes from 'propTypes';
import useConfirm from 'components/Confirmation/useConfirm';
import { sanitizeLinkify } from 'services/sanitizationService';
import linkifyHtml from 'linkify-html';
import MemberQuickActions from 'containers/Member/QuickActions/MemberQuickActions';

const ContactDirectoryDataContact = ({ contactId, ContentWrapper, variant, onSuccess, onDeleteSuccess, ...rest }) => {

  const dispatch = useDispatch();
  const confirm = useConfirm();

  useEffect(() => {
    if (!contactId) {
      return;
    }
    dispatch(contactActions.getContact({ contactId }));
  }, [contactId, dispatch]);

  const getContactSelector = useSelector(contactSelectors.getContactSelector);
  const contact = getContactSelector(contactId);

  const { t } = useTranslation(['component']);

  const workspaceCover = useWorkspaceCover();

  const initialValues = {
    contactId: contact?.id,
    name: contact?.name,
    contactNumber: contact?.contactNumber,
    contactEmail: contact?.contactEmail,
    structuralUnit: contact?.structuralUnit,
    info: contact?.info,
  };

  const onEditClick = () => {
    setEditActive(true);
  };

  const onDeleteClick = () => {
    confirm({
      title: t('contactDirectory.itemDeleteConfirmation.title', { name: contact?.name }),
      submit: t('contactDirectory.itemDeleteConfirmation.submit'),
      cancel: t('contactDirectory.itemDeleteConfirmation.cancel'),
    }).then(
      () => {
        dispatch(contactActions.deleteContact({ contactId }));
        setEditActive(false);

        if (onDeleteSuccess) {
          onDeleteSuccess();
        }
      },
      () => {},
    );
  };

  const onDiscardClick = () => {
    setEditActive(false);
  };

  const combinedOnSuccess = (...args) => {
    setEditActive(false);

    if (onSuccess) {
      onSuccess(...args);
    }
  };

  const [editActive, setEditActive] = useState(false);

  const editableMode = variant === 'editable';
  const createMode = variant === 'create';

  const formMode = (editableMode && editActive) || createMode;

  const contactInfo = sanitizeLinkify(
    linkifyHtml(contact?.info || '', {
      target: '_blank',
      attributes: {
        rel: 'noopener noreferrer nofollow',
      },
    }),
  );

  return (
    <Form
      start={createMode ? contactTypes.storeContact : contactTypes.updateContact}
      resolve={createMode ? contactTypes.storeContactSuccess : contactTypes.updateContactSuccess}
      reject={createMode ? contactTypes.storeContactFailure : contactTypes.updateContactFailure}
      initialValues={initialValues}
      onSuccess={combinedOnSuccess}
      key={editActive} // Refreshes the form with fresh values
      {...rest}
    >
      {({ handleSubmit, values }) => (
        <>
          <Header name={values?.name || contact?.name} cover={values?.cover || contact?.cover || workspaceCover} avatar={false} />

          {!formMode && (
            <Grid container justifyContent="space-between" alignItems="center" wrap="nowrap">
              <Grid item xs>
                <ContentWrapper>
                  <MemberQuickActions
                    contactEmail={contact?.contactEmail}
                    contactNumber={contact?.contactNumber}
                  />
                </ContentWrapper>
              </Grid>
            </Grid>
          )}

          <ContentWrapper>
            <Grid container spacing={2} direction="column" wrap="nowrap">

              {formMode && (
                <Grid item>
                  <FormField margin="none" label={t('contactDirectory.itemInfo.nameLabel')} name="name" />
                </Grid>
              )}

              {(contact?.contactNumber || formMode) && (
                <Grid item>
                  {formMode
                    ? <FormField margin="none" label={t('contactDirectory.itemInfo.contactNumberLabel')} name="contactNumber" />
                    : <CopyableTextField label={t('contactDirectory.itemInfo.contactNumberLabel')} value={contact.contactNumber} />}
                </Grid>
              )}

              {(contact?.contactEmail || formMode) && (
                <Grid item>
                  {formMode
                    ? <FormField margin="none" label={t('contactDirectory.itemInfo.emailLabel')} name="contactEmail" />
                    : <CopyableTextField label={t('contactDirectory.itemInfo.emailLabel')} value={contact.contactEmail} />}
                </Grid>
              )}

              {(contact?.structuralUnit || formMode) && (
                <Grid item>
                  {formMode
                    ? <FormField margin="none" label={t('contactDirectory.itemInfo.structuralUnitLabel')} name="structuralUnit" />
                    : <CopyableTextField label={t('contactDirectory.itemInfo.structuralUnitLabel')} value={contact.structuralUnit} />}
                </Grid>
              )}

              {formMode && (
                <Grid item>
                  <FormField margin="none" label={t('contactDirectory.itemInfo.infoLabel')} name="info" multiline minRows={3} maxRows={10} />
                </Grid>
              )}

              {contact?.info && !formMode && (
                <>
                  <Grid item><Divider light /></Grid>
                  <Grid item sx={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: contactInfo }} />
                </>
              )}
            </Grid>
          </ContentWrapper>

          {(variant === 'editable' || variant === 'create') && (
            <Grid container>
              <Grid item xs>
                <ContentWrapper>
                  <Grid container spacing={2} direction="column" wrap="nowrap">
                    <Grid item>
                      {!formMode && (
                        <Grid container direction="row" spacing={4} alignItems="center" justifyContent="center">
                          <Grid item><Button variant="text" color="inherit" startIcon={<EditIcon />} onClick={onEditClick}>{t('contactDirectory.contactForm.edit')}</Button></Grid>
                          <Grid item><Button variant="text" color="inherit" startIcon={<TrashIcon />} onClick={onDeleteClick}>{t('contactDirectory.contactForm.delete')}</Button></Grid>
                        </Grid>
                      )}

                      {formMode && (
                        <Grid container direction="row" spacing={4} alignItems="center" justifyContent="flex-end">
                          <Grid item><Button variant="text" onClick={onDiscardClick}>{t('contactDirectory.contactForm.discard')}</Button></Grid>
                          <Grid item><Button onClick={handleSubmit}>{t('contactDirectory.contactForm.save')}</Button></Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </ContentWrapper>
              </Grid>
            </Grid>
          )}

        </>
      )}
    </Form>
  );
};

ContactDirectoryDataContact.propTypes = {
  contactId: PropTypes.any,
  ContentWrapper: PropTypes.elementType,

  variant: PropTypes.oneOf(['view', 'editable', 'create']),
  onSuccess: PropTypes.func,
  onDeleteSuccess: PropTypes.func,
};

export default ContactDirectoryDataContact;
